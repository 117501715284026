import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:

import Collapse from 'bootstrap/js/dist/collapse';

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as threesixtyViewer from './scripts/threesixty-viewer';
threesixtyViewer.init();

import * as affix from '@elements/affix';
affix.init({mediaQuery: '(min-width: 768px)'});

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-right'
});

import * as hotspot from './scripts/hotspot';
hotspot.init();

import * as googleMap from './scripts/google-map';
googleMap.init();