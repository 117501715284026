import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, closest, on, findIn, find, addClass, hasClass} from '@elements/dom-utils';

export function init() {
    let viewers = findAllIn('.js-threesixty-viewer', document);
    console.log(viewers)
    viewers.map(function (viewer) {
        import('photo-sphere-viewer').then(function () {
           let id = viewer.id;
           let imgPath = viewer.getAttribute("data-img-path");
           let caption = viewer.getAttribute("data-caption");

            let sphere = new PhotoSphereViewer.Viewer({
                container: id,
                panorama: imgPath,
                loadingImg: 'https://photo-sphere-viewer.js.org/assets/photosphere-logo.gif', //optional animated loading gif
                caption: caption
            });
        });
    });
}

function createViewer(viewerContainer, id, imgPath, caption) {

}
