"use strict";

import * as googleMap from "@elements/google-map";
import {find, findIn, findAll} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";

const isMobile = () => matchMedia('(max-width: 767px)').matches;


export function init () {
    onFind('.js-google-map', function (mapElement) {

        let poiVarName = mapElement.dataset.googleMapPois;
        let pois = [];

        if (poiVarName) {
            if (!window['_googleMapPois']) {
                console.error('_googleMapPois is not set');
            } else if (!window._googleMapPois[poiVarName]) {
                console.error(poiVarName + ' is not set in _googleMapPois', _googleMapPois);
            } else {
                pois = _googleMapPois[poiVarName];
            }
        }

        let map = googleMap.init({
            element: mapElement,
            pois: pois,
            mapOptions: {
                center: {lat: 47.0125700, lng: 10.2917900},
                zoom: 14,
                maxZoom: 18,
                scrollwheel: true,
                mapTypeControl: false,
                streetViewControl: false,
                style: getMapStyling()
            },
            poiStyles: () => createPoiStyles(),
            infoBoxOptions: () => getGoogleInfoBoxOptions(),
            clustering: false,
            clusteringOptions: createClusterStyles(),
            onActivateMarker: (marker, api) => onActivateMarker(marker, api),
            onDeactivateMarker: (marker, api) => onDeactivateMarker( marker, api),
        });

        onEnterViewPort(find('.js-google-map'), function (element) {
            googleMap.getApi(element).then((api) => {
                api.centerMap();
            });
        }, {
            offset: window.innerHeight * 1.5
        });
    });
}

export function createClusterStyles() {
    return {
        default: {
            styles: [{
                height: 60,
                url: "/static/build/img/pois/cluster.svg",
                width: 60,
                textSize: 18,
                textColor: "#FFF"
            }]
        }
    }
}

export function createPoiStyles() {
    return {
        main: {
            default: {
                url: '/static/build/img/pois/poi-main.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            },
            active: {
                url: '/static/build/img/pois/poi-main-active.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            }
        }
    }
}
export function onActivateMarker(marker, api) {
    console.log(marker);
    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = api.showInfoBoxByMarker(
            marker,
            ` <div class="loading-spinner" aria-label="loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>`
        );

        let contentPromise = fetch(marker.detailInfoBoxUrl, {
            method: 'GET',
            headers: {"content-type": "application/json"}
        }).then((res) => {
            return res.json()
        });

        Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
            infoBox.setContent(response.html);
        });
    }
}

export function onDeactivateMarker(marker, api) {
    api.closeInfoBoxByMarker(marker);
}

export function getMapStyling() {
    return  [
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ];
}

export function getGoogleInfoBoxOptions(){
    let width = isMobile() ? "200px" : "300px";
    let offsetX = isMobile() ? -100 : -150;
    let offsetY = isMobile() ? -22 : -20;

    return {
        zIndex:-1,
        pixelOffset: new google.maps.Size(offsetX, offsetY),
        boxStyle: {width: width, padding: "0"},
        boxClass: "info-box",
        closeBoxURL: "/static/img/map/close.svg"
    };
}
